import { Button, Dialog, styled, DialogActions } from '@mui/material';

const DialogBox = styled('div')(() => ({
  width: 360,
  padding: '32px',
  marginLeft: 'auto',
  marginRight: 'auto',
}));

const Title = styled('h4')(() => ({
  margin: 0,
  marginBottom: '8px',
  textTransform: 'capitalize',
}));

const ConfirmationDialog = ({
  open,
  onConfirmDialogClose,
  text,
  title = 'Confirmation',
  onYesClick,
}) => {
  return (
    <Dialog maxWidth="xs" open={open} onClose={onConfirmDialogClose}>
      <DialogBox>
        <Title>{title}</Title>
        <p>{text}</p>

        <DialogActions>
          <Button autoFocus onClick={onYesClick}>
            DELETE
          </Button>

          <Button onClick={onConfirmDialogClose}>
            CANCEL
          </Button>  
        </DialogActions>
      </DialogBox>
    </Dialog>
  );
};

export default ConfirmationDialog;
