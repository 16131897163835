import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const Jobs = Loadable(lazy(() => import('./Jobs')));
const JobForm = Loadable(lazy(() => import('./JobForm')));
const JobExecutions = Loadable(lazy(() => import('./JobExecutions')));

const jobRoutes = [
  { path: '/jobs', element: <Jobs /> },
  { path: '/jobs/:id', element: <JobForm /> },
  { path: '/jobs/:id/executions', element: <JobExecutions /> }
];

export default jobRoutes;
