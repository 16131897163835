import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const ApiKeys = Loadable(lazy(() => import('./ApiKeys')));
const ApiSecurity = Loadable(lazy(() => import('./ApiSecurity')));

const userRoutes = [
  { path: '/api-keys', element: <ApiKeys />, auth: authRoles.admin },
  { path: '/api-security', element: <ApiSecurity />, auth: authRoles.admin },
];

export default userRoutes;
