import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Credentials = Loadable(lazy(() => import('./Credentials')));
const CredentialForm = Loadable(lazy(() => import('./CredentialForm')));

const userRoutes = [
  { path: '/credentials', element: <Credentials />, auth: authRoles.credentials },
  { path: '/credentials/:id', element: <CredentialForm />, auth: authRoles.credentials },
];

export default userRoutes;
