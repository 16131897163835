import { Breadcrumbs, Hidden, Icon, styled, useTheme } from '@mui/material';
import { NavLink } from 'react-router-dom';

const BreadcrumbRoot = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
}));

const BreadcrumbName = styled('h4')(() => ({
  margin: 0,
  fontSize: '16px',
  paddingBottom: '1px',
  verticalAlign: 'middle',
  textTransform: 'capitalize',
}));

const SubName = styled('span')(({ theme }) => ({
  textTransform: 'capitalize',
  color: "#5272f9"
}));

const Breadcrumb = ({ routeSegments, name }) => {
  const theme = useTheme();
  const hint = theme.palette.text.hint;

  return (
    <BreadcrumbRoot>
      {routeSegments ? (
          <Breadcrumbs
            separator={<Icon sx={{ color: hint }}>navigate_next</Icon>}
            sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>

            {routeSegments
              ? routeSegments.map((route, index) => {
                return <NavLink key={index} to={route.path}>
                  <SubName className="actionlink">{route.name}</SubName>
                </NavLink>
                })
              : null}
          </Breadcrumbs>
      ) : null}

      <Icon sx={{ color: hint }}>navigate_next</Icon>

      {name !== undefined ? (
        <Hidden xsDown>
          <BreadcrumbName>{name}</BreadcrumbName>
        </Hidden>
      ) : null}
    </BreadcrumbRoot>
  );
};

export default Breadcrumb;
