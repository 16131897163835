import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Users = Loadable(lazy(() => import('./Users')));
const UserForm = Loadable(lazy(() => import('./UserForm')));

const userRoutes = [
  { path: '/users', element: <Users />, auth: authRoles.users },
  { path: '/users/:id', element: <UserForm />, auth: authRoles.users },
];

export default userRoutes;
