import axios from 'axios';
import Auth from "./Auth";

class Api {
    constructor() {
        axios.defaults.withCredentials = true; 
        this.baseUrl = "https://api.cloudyscheduler.com/";
        this.redirectUrl = "https://www.cloudyscheduler.com/";
    }

    async get(path, headers = undefined) { 
        return this.execute(path, "GET", undefined, headers);
    }
    
    async delete(path, headers = undefined) { 
        return this.execute(path, "DELETE", undefined, headers);
    }

    async save(path, id, body, headers = undefined) {
        if (id === "new") {
            return this.post(path, body, headers);
        } else {
            return this.put(path + "/" + id, body, headers);
        }
    }

    async put(path, body, headers = undefined) { 
        return this.execute(path, "PUT", JSON.stringify(body), headers);
    }

    async post(path, body, headers = undefined) { 
        return this.execute(path, "POST", JSON.stringify(body), headers);
    }

    async execute(path, method, data = undefined, headers = undefined) { 
        let url = this.baseUrl + path;

        return Auth.getAccessToken().then(async (accessToken) => {
            try {
                const response = await axios({ method, url, data, headers: this.buildRequestHeaders(headers, accessToken) });
                return response.data;
            } catch (error) {
                if (error.response.status === 401) {
                    window.location.href = this.redirectUrl;
                }
                throw this.getError(error);
            }
        });
    }

    buildRequestHeaders(headers = undefined, accessToken) {
        let requestHeaders = { 'Accept': 'application/json', 'Content-Type': 'application/json' };
        requestHeaders.Authorization = ('Bearer ' + accessToken);
        
        if (headers !== undefined) requestHeaders = {...requestHeaders, ...headers };   
        return requestHeaders;
    }

    getError(error) {
        if (typeof error === 'string') {
            return { message: error };
        }
    
        let json;
        if (error.response) {
            json = typeof error.response.data === 'string' ? { } : error.response.data;
    
            if (!json.message && json.error) {
                json.message = typeof json.error === 'string' ? json.error : json.error.message;       
            }
    
            if (!json.message || json.message === "") {
                if (error.response.status === 403) {
                    json.message = "Action not allowed.";
                } else {
                    json.message = "Sorry, an unknown error has occured.";
                }
            }
    
            json.status = error.response.status;
            json.statusText = error.response.statusText;    
        } else if (error.message) {
            return error;
        } else {
            json = {};
            json.message = error.message;
        }
        return json;
    }    
}

export default new Api();