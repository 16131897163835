import React, { createContext, useEffect, useReducer } from 'react'
import { MatxLoading } from 'app/components'
import Auth from "../utils/Auth";

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => { },
    loginSuccess: () => { }
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const login = () => {
        Auth.signIn();
    }

    const loginSuccess = (user) => {
        dispatch({
            type: 'LOGIN',
            payload: { user, }
        });
    }

    const logout = () => {
        Auth.signOut();        
        dispatch({ type: 'LOGOUT' });        
    }

    useEffect(() => {      
        dispatch({
            type: 'INIT',
            payload: {
                isAuthenticated: false,
                user: null,
            },
        });
    }, []);

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
                loginSuccess,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
