import AuthGuard from 'app/auth/AuthGuard';
import NotFound from 'app/views/sessions/NotFound';
import sessionRoutes from 'app/views/sessions/SessionRoutes';
import jobRoutes from 'app/views/jobs/JobRoutes';
import userRoutes from 'app/views/users/UserRoutes';
import apikeysRoutes from 'app/views/api-keys/ApiKeysRoutes';
import credentialRoutes from 'app/views/credentials/CredentialRoutes';
import { Navigate } from 'react-router-dom';
import MatxLayout from './components/MatxLayout/MatxLayout';

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [...jobRoutes, ...userRoutes, ...apikeysRoutes, ...credentialRoutes],
  },
  ...sessionRoutes,
  { path: '/', element: <Navigate to="jobs" /> },
  { path: '*', element: <NotFound /> },
];

export default routes;
