export const SET_USER_NAVIGATION = 'SET_USER_NAVIGATION';

const getfilteredNavigations = (navList = [], roles) => {
  return navList.reduce((array, nav) => {
    if (nav.auth) {
      if (nav.auth.some(r => roles.indexOf(r) >= 0)) {
        array.push(nav);
      }
    } else {
      if (nav.children) {
        nav.children = getfilteredNavigations(nav.children, roles);
        array.push(nav);
      } else {
        array.push(nav);
      }
    }
    return array;
  }, []);
};

export function getNavigationByUser(user) {
  return (dispatch, getState) => {
    let { navigations = [] } = getState();

    let filteredNavigations = getfilteredNavigations(navigations, (user.roles || "").split(","));

    dispatch({
      type: SET_USER_NAVIGATION,
      payload: [...filteredNavigations],
    });
  };
}
