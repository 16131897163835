import * as Msal from "@azure/msal-browser";

class Auth {
  constructor() {
    this.accessToken = null;
    this.accountId = null;
    this.authDomain = "cloudyscheduler.b2clogin.com";
    this.authFlow = "B2C_1_SignUpSignIn";
    this.profileFlow = "B2C_1_ProfileEdting";
    this.redirectUrl = "https://www.cloudyscheduler.com";
    this.accessScope = "https://cloudyscheduler.com/api/Jobs.Read";
    this.authorityUrl = "https://cloudyscheduler.b2clogin.com/cloudyscheduler.onmicrosoft.com/";
    let redirectUri = "https://app.cloudyscheduler.com/";
    
    if (window.location.host.indexOf("localhost") === 0) {
      redirectUri = "http://localhost:3000/";
    }

    this.msalConfig = {
      auth: {
        clientId: "975c361a-2f26-4fd4-b611-1780eeeb8f20",
        authority: this.authorityUrl + this.authFlow,
        validateAuthority: true,
        redirectUri: redirectUri,
        postLogoutRedirectUri: this.redirectUrl,
        navigateToLoginRequestUrl: true,
        knownAuthorities: [this.authDomain]
      },
      cache: {
        cacheLocation: "localStorage", // "sessionStorage" is more secure but "localStorage" gives you SSO between tabs
        storeAuthStateInCookie: false
      }
    };

    this.msalInstance = new Msal.PublicClientApplication(this.msalConfig);    

    this.msalInstance.handleRedirectPromise()
        .then(response => {
            if (response) {
                var authContext = (response.idTokenClaims['tfp'] || response.idTokenClaims['tcr'] || "").toUpperCase();

                if (authContext === this.authFlow.toUpperCase()) {
                    this.handleAuthResponse(response);
                }
            }
        })
        .catch(error => {
            console.log(error);
        });
  }

  async handleAuthResponse(response) {
    if (response !== null) {
        this.accessToken = response.accessToken;
        this.setAccount(response.account);
    } else {
        this.selectAccount();
    }
  }

  setAccount(account) {
    this.accountId = account.homeAccountId;

    let firstName = "Unknown";
    let lastName = "User";

    if (account.idTokenClaims !== undefined) {
        firstName = account.idTokenClaims.given_name;
        lastName = account.idTokenClaims.family_name;
    }

    this.dispatch('SignedIn', { firstName, lastName });
  }

  selectAccount() {
    const currentAccounts = this.msalInstance.getAllAccounts();

    if (currentAccounts.length < 1) { 
        this.dispatch('SignedOut', {});
        return;
    } else if (currentAccounts.length > 1) {
        const accounts = currentAccounts.filter(account =>
            account.homeAccountId.toUpperCase().includes(this.authFlow.toUpperCase()) &&
            account.idTokenClaims.iss.includes(this.authDomain) &&
            account.idTokenClaims.aud === this.msalConfig.auth.clientId 
        );

        if (accounts.length > 1) {
            if (accounts.every(account => account.localAccountId === accounts[0].localAccountId)) {
                // All accounts belong to the same user         
                this.setAccount(accounts[0]);
            } else {
                // Multiple users detected. Logout all to be safe
                this.signOut();
            };
        } else if (accounts.length === 1) {
            this.setAccount(accounts[0]);
        }
    } else if (currentAccounts.length === 1) {
        this.setAccount(currentAccounts[0]);
    }
  }

  async getAccessToken() {
    if (this.accessToken) return Promise.resolve(this.accessToken);

    const tokenRequest = {
        scopes: [this.accessScope],
        forceRefresh: false,
        account: this.msalInstance.getAccountByHomeId(this.accountId)
    };
   
    return this.msalInstance.acquireTokenSilent(tokenRequest)
        .then((response) => {
            // In case the response from B2C server has an empty accessToken field
            // throw an error to initiate token acquisition
            if (!response.accessToken || response.accessToken === "") {
                throw new Msal.InteractionRequiredAuthError();
            } else {
                console.log("access_token acquired at: " + new Date().toString());
                this.accessToken = response.accessToken;
                return this.accessToken;
            }
        }).catch(error => {
            console.log("Silent token acquisition fails. Acquiring token using popup. \n", error);
            if (error instanceof Msal.InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                return this.msalInstance.acquireTokenRedirect(tokenRequest);
            } else {
                console.log(error);   
            }
    });
  }

  signIn() { 
    const loginRequest = { scopes: ["openid", this.accessScope], prompt: "select_account" }; 
    this.msalInstance.loginRedirect(loginRequest);
  }

  signOut() {
    const logoutRequest = {
        postLogoutRedirectUri: this.redirectUrl,
        mainWindowRedirectUri: this.redirectUrl
    };
    this.msalInstance.logoutRedirect(logoutRequest);
  }

  editProfile() {
    const editProfileRequest = {
        authority: this.authorityUrl + this.profileFlow,
        loginHint: this.msalInstance.getAccountByHomeId(this.accountId).username
    };
    this.msalInstance.loginRedirect(editProfileRequest);
  }

  // allow controls to subscribe to events
  on(name, callback) {
    var callbacks = this[name];
    if (!callbacks) {
      this[name] = [callback];
    } else {
      callbacks.push(callback);
    }
  }

  // emit events to subscribers
  dispatch(name, event) {
    const callbacks = this[name];
    if (callbacks) callbacks.forEach(callback => callback(event));
  }  
}

export default new Auth();
